
<script>
import ComboboxSkills from '@/components/general/ComboboxSkills'
import Loading from '@/components/general/Loading'
export default {
  name: 'Skills',
  components: {
    ComboboxSkills,
    Loading
  },
  data () {
    return {
      ready: false,
      initialSkillsSuggestions: [],
      skillsSuggestions: [],
      selectedSkills: [],
      search: null
    }
  },
  computed: {
    setTheme () {
      return this.getTheme(this.$route.query)
    }
  },
  methods: {
    handleSaveSkills () {
      this.$store.dispatch('attemptUpdateUserProfile', { filter: 'skills', skills: this.selectedSkills.map(skill => skill) })
      this.$emit('progressChange', { stepProgress: 3 })
      this.$router.push({ name: 'newaccount.home.firstAccess.categories', query: { ...this.$route.query } })
    },
    handleBackPage () {
      this.$emit('progressChange', { stepProgress: 1 })
      this.$router.push({ name: 'newaccount.home.firstAccess.profile', query: { ...this.$route.query }, params: { imported: false } })
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('attemptGetUserProfile')
    ]).then((data) => {
      this.selectedSkills = data[0].skills.map((skill) => skill)
      this.ready = true
    })
    this.$emit('progressChange', { stepProgress: 2 })
  }
}
</script>
<template>
  <v-card flat outlined class="skills--card mt-3">
    <v-app-bar flat class="skills-card--header">
      <p class="text--primary">{{ $t('first.access.skills:title') }}</p>
    </v-app-bar>
    <loading v-if="!ready"></loading>
    <div v-if="ready">
      <v-card-text class="skills-selection--title">
        <h4 class="mb-1 mt-8">{{ $t('first.access.skills:title.card') }}</h4>
        <p>{{ $t('first.access.skills:subtitle.card') }}</p>
      </v-card-text>
      <div class="text--body pa-5">
        <combobox-skills v-if="ready" v-model="selectedSkills" :themeColor="setTheme.primaryColor"/>
      </div>
    </div>
    <v-card-actions class="skills-card--footer">
      <v-btn class="btn bold transform-unset back" text @click="handleBackPage()">{{ $t('global:back') }}</v-btn>
      <v-btn class="btn bold transform-unset next"  :color="setTheme.primaryColor" dark @click="handleSaveSkills()">{{ $t('global:next.step') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.skills--card{
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin-bottom: 40px;
  .skills-card--header {
    display: flex;
    justify-content: center;
    background-color: #F8F8F8;
    border-bottom: thin solid rgba(0,0,0,.12);
    p {
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      color: #212121;
      margin: 0;
    }
  }
  .skills-selection--title {
    h4 {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #212121;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #A0A0A0;
      margin-bottom: 0;
    }
  }
  .text--body {
    .body-subtitle{
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        font-weight: 400;
        line-height: 20px;
        color: #A0A0A0;
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
    .chip-suggestions {
      .v-icon {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #FFFFFF;
        border-radius: 900px;
        color: #A0A0A0;
        font-size: 20px;
        width: 24px;
        height: 24px;
      }
    }
  }
  .skills-card--footer {
    display: flex;
    justify-content: space-between;
    background-color: #F8F8F8;
    border-top: thin solid rgba(0,0,0,.12);
    .back {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color:#7E7D77;
    }
    .next {
      width: 123px;
      height: 36px;
      border-radius: 4px;
    }
  }
}
</style>
